@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.button{
  position: absolute;
  width:7%;
  height:100%; 
  display:flex;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 4;
  @include mq("tablet-small", max) {
    display:none;
  }

}

.grey{background-color: rgba(242, 239, 234, 0.5);}
.imageContainer{
  width:25%;
  margin:auto;
}

.next{
  right:29px;
}
.previous{
  left:29px;
}
.next, .previous{
  &[aria-disabled="true"] {
    opacity: 0.2;
  }
}

:global(.teams_section){
  .button{
    width: 5%;
    height: calc(100% - 70px);
    bottom:0;
  }
  .imageContainer{
    width:34%;
  }
  @include mq("tablet-wide") {
    .next{
      right: 47px;
    }
    .previous{
      left:47px;
    }
  }
  :global(.sliderControls:not(.tournamentSliderControls)){
    .button{
      width:7%;
      height: 100%;
      @include mq("tablet-small", max) {
        display:flex;
      }
    }
    .imageContainer{
      width:57%;
    }
    .next{
      right: -20px;
    }
    .previous{
      left:-20px;
    }
    @include mq("tablet-wide") {
      .next{
        right: -35px;
      }
      .previous{
        left:-35px;
      }
    }
  }
}