@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.no_teams{
  padding: 40px 0 40px;
}

.playersContainer {
  position: relative;
  :global(.no_teams) & {
    margin-top: 40px;

    .playerItem{
      margin:0;
    }
  }
 

  .tournamentSlider{
    width: 100%;
  }
  .playerSliderContainer{
    width:88%;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    @include mq("tablet-small", max) {
      flex-direction: column;
     }
    .playerSlider{
      position: relative;
      width: 100%;
      @include mq("tablet-small") {
        width:46%;
      }
    }
  }
  
  .playerItem {
    // max-width: 50%;
    // // @include mq("phone-wide") {
    // //   max-width: 33%;
    // // }
    // @include mq("tablet-small") {
    //   max-width: 50%;
    // }
    // // @include mq("tablet-wide") {
    // //   max-width: 33%;
    // // }
    @include mq("desktop") {
      max-width: 25%;
    }
    
    width:100%;
    .player {
      position: relative;

      .playerImage {
        display: flex;
        margin-bottom: 10px;
        background: var(--teamColor);
        padding:3px;
        height:0;
        padding-bottom:100%;
        @include rounded-hex($main-radius: 40%,
          $rounding-radius: 5%,
          $rotated: true);
        margin-bottom: 0;
        img {
          position: absolute;
          height: calc(100% - 6px);
          width: calc(100% - 6px);
          object-fit: cover;
          @include rounded-hex($main-radius: 40%,
            $rounding-radius: 5%,
            $rotated: true);
        }
      }
      .points {
        background: var(--teamColor);
        color: #fff;
        width: 30%;
        max-width: 60px;
        justify-content: center;
        display: flex;
        align-items: center;

        position: absolute;
        bottom: 0;
        right: 5%;
        text-align: center;
        font-size: 30px;
        font-family: 'RomanaBT-Bold', sans-serif;
        @include rounded-hex($main-radius: 40%,
          $rounding-radius: 5%,
          $rotated: true);

        @include mq("desktop") {

          font-size: 25px;
        }

        .pointsHeight {
          margin-top: 100%;
        }
      }
    }
    .playerName {
      font-family: 'PitchSansWeb-Regular', sans-serif;
      font-size: 19px;
      text-align: center;
      margin: 2px 0 10px 0;
      text-transform: capitalize;
      color: $darkblue;

    }
  }


  .teamPlayers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;     
    align-items: center;
    padding-top:32px;
    // height: calc(100% - 32px);
    width: 100%;
  }

  .tournamentTeam{
    margin-top: 70px;
    position: relative;
    border: 2px solid var(--teamColor);
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-of-type{
      
      &:before{
        content: "WINNING TEAM!";
        font-family: 'RomanaBT-Bold', sans-serif;
        color:var(--teamColor);
        font-size: 22px;
        position: absolute;
        top: -51px;
        width: 100%;
        text-align: center;
      }
    }
    .playerItem {
      width: 31%;
      @include mq("tablet-small") {width: 45%;}
      @include mq("tablet") {width: 31%;}
      @include mq("desktop") {width: 25%;}
      @include mq("tablet") {
        &.players_1,&.players_2,&.players_3,&.players_4{margin:0 5%;}
        &.players_5,&.players_6{margin: 0 1%;}
      }
      @include mq("tablet-small", max) {
        &.players_1,&.players_2,&.players_3,&.players_4{margin:0 5%;}
        &.players_5,&.players_6{margin: 0 1%;}
      }
    }
    .teamTitle{
      top:-24px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      position: absolute;
      background: $white;
      padding: 0 20px;
      font-family: 'RomanaBT-Bold', sans-serif;
      font-size: 30px;
      color: $dark;
      .teamName{}
      .teamPoints{
        font-family:  'PitchSansWeb-Bold', sans-serif;
        color: $white;
        background-color: var(--teamColor);
        margin-left:15px;
        letter-spacing: -3px;
        text-indent: -3px;
        font-size: 38px;
        width: 55px;
        text-align: center;
      }
    }
  }
  
}

.pointsContainer{
  display: flex;
  margin: auto;
  justify-content: center;
  position: relative;
  margin-top: -20px;
  overflow: hidden;
  @include mq("tablet-wide") {
    margin-top: -40px;
  }
  .teamTitle{
    //padding:0 2px;
    color:#fff;
    display: flex;
    flex:1; 
    justify-content: flex-end;
    align-items: center;
    font-family:  'PitchSansWeb-Bold', sans-serif;

    &:last-of-type{
      flex-direction: row-reverse;
      .teamName{
        &:before{
          border-width: 29px 29px 0 0;
          border-color: var(--teamColor) transparent transparent transparent;
          right:-29px;
          left:auto;
        }
        @include mq("tablet-wide") {
          &:before{
            border-width: 44px 44px 0 0;
            right:-44px;
          }
        }
      }
    }
    .teamName{
      background: var(--teamColor);
      padding:2px 20px;
      font-size: 30px;
      line-height: 29px;
      height: 29px;
      box-sizing: border-box;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top:0;
        left:-29px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 29px 29px;
        border-color: transparent transparent var(--teamColor) transparent;
      }
     
      @include mq("tablet-wide") {
        font-size: 45px;
        line-height: 44px;
        height: 44px;
        &:before{
          left:-44px;
          border-width: 0 0 44px 44px;
        }
      }
    }
    .teamPoints{
      background: var(--teamColor);
      width:50px;
      height: 38px;
      color: $blueprint;
      border:2px solid #fff;
      font-size: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -5px;
      text-indent: -5px;
      @include mq("tablet-wide") {
        letter-spacing: -10px;
        text-indent: -10px;
        width:86px;
        height: 73px;
        font-size: 67px;
      }
    }
  }
}