@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.sliderContainer {
    display: flex;
    position: relative;
}
.mobileContainer{
  width: 85%;
  margin: auto;
}

.groupPhotoItem img {
  box-sizing: border-box;
  margin: auto;
  border-radius: 7px;
  border: 4px solid var(--teamColor);
  width: 100%;
  height: auto;
  position: relative;
  transform: none;
  top:0;
  left:0;
  
}