@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";


.gameBackground{
  max-width:100%;
  border-radius: 4px;
}
.playerContainer{
  display: flex;
  position: absolute;
  width:80%;
  height:50%;
  top:35%;
  left:58%;
  transform: translate(-50%, -50%);
  .gamePlayer{
    @include rounded-hex($main-radius: 40%,
    $rounding-radius: 5%,
    $rotated: true);
    padding: 4px;
    height: 0;
    width:30%;
    min-width: 30%;
    padding-bottom: 30%;
    position: relative;
    z-index: 10;
    background-color: $purple;
    img{
      @include rounded-hex($main-radius: 40%,
              $rounding-radius: 5%,
              $rotated: true);
      position: absolute;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      object-fit: cover;
    }
  }
  @include mq("tablet-small") {
    width:90%;
    height:80%;
    top:42%;
  }
  @include mq("tablet") {
    width:80%;
    height:50%;
    top:35%;
  }
}
.textContainer{
  margin-top:4%;
  min-width: 70%;
  .playerName{
    margin-left:-22%;
    padding-left:22%;
    padding-right:4%;
    position: relative;
    font-family: 'PitchWeb-Bold', serif;
    letter-spacing: 2px;
    font-size: 1.7vw;
    min-width:34%;
    line-height: 44px;
    height:44px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $purple;
    color: $white;
    fill: $white;
    &:before{
      content: '';
      position: absolute;
      top:0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 44px 24px 0 24px;
      border-color: $purple transparent transparent transparent;
      right:-24px;
      z-index: -1;
    }
    
    @include mq("tablet-wide", max) {
        font-size: 2vw;
        min-width:44%;
        line-height: 34px;
        height:34px;
        &:before{
          border-width: 34px 24px 0 24px;
        }
      }
      @include mq("tablet-small", max) {
        font-size: 5vw;
        min-width:44%;
        line-height: 34px;
        height:34px;
        &:before{
          border-width: 34px 24px 0 24px;
        }
      }
  }
  .gameMessage{
    width:90%;
  font-family: 'RomanaBT-Bold', sans-serif;
  text-transform: uppercase;
  //margin-top: 20px;
  color: white;
  font-size: 2.3vw;
  fill: #fff;
  padding-top: 3%;
  padding-left:2%;
  @include mq("tablet-small", max) {
    font-size: 5vw;
  }
    
  }
}

.play{
  position: absolute;
  position: absolute;
  width:14%;
  top:78%;
  left:50%;
  transform: translate(-50%, -50%);
}




