@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.section{
    @include mq("tablet-wide") {
        padding-bottom: 110px!important;
    }
}

.sliderContainer {
    display: flex;
    position: relative;
}

/* .loopcamItem {
    margin: 0 10px;
} */

.loopcamItem iframe {
    width: 335px;
    height: 200px;
    pointer-events: none;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    @include mq("tablet-small") {
        width: 100%;
        height: 100%;
      }
}
:global(.fslightbox-source-inner){
    .loopcamItem{
        width:90vw!important;
        height:auto!important;
    }
}
.iosPlaceholder{
    width:100%;
}