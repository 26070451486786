.itemHover{
  width:400px;
  height:400px;
  position: absolute;
  top:0;
  transform: translate(-50%, -50%);

  transform-origin: center;
  opacity:0;
  visibility: hidden;
  z-index:50;
  &[data-type="VIDEO"]{
    transition: visibility 0.25s, opacity 0.25s, width 0.25s ,height 0.25s;
  }
  
  &.active{
    opacity:1;
    visibility:visible;
    transform: translate(-50%, -50%) ;
    transition: visibility 0.25s, opacity 0.25s;
    // width:400px!important;
    // height:400px!important;
    &[data-type="VIDEO"]{
      transition: visibility 0.25s, opacity 0.25s, width 0.25s ,height 0.25s;
      min-width:500px;
      min-height: 281px;
    }
    
  }
  &.activeStatic{
    opacity:1;
    visibility:visible;
    transform: translate(-50%, -50%);
    transition: visibility 0.25s, opacity 0.25s;
    &[data-type="VIDEO"]{
      transition: visibility 0.25s, opacity 0.25s, width 0.25s ,height 0.25s;
    }
  }
  &.hide{
    display: none;
    width:200px;
    height:112.5px;
  }


  &[data-type="PHOTO"], &[data-type="VIDEO"]{
    .contentContainer{
      width:100%;
      height:100%;
      position: relative;
      overflow: hidden;
      
      img,iframe{
        width:100%;
        height:100%;
        pointer-events: none;
      }
      border-radius: 5px 5px 0 0;
      background: #0E1915;
    }
  }
  .fullSize{
    position: relative;
    width:100%;
    height:100%;
    z-index: 2;
  }
  .contentContainer{
    width:100%;
    height:100%;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    background: #0E1915;
  }
  .shareBarContainer{
    overflow: hidden;
    background:  rgba(14, 25, 21, 0.9);
    border-radius: 0 0 5px 5px;
  }

  .loading{
    z-index:1;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 10px solid #d4d4d4;
    border-top-color: #55BEC7;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}