// Media queries
$breakpoints: ("phone": 375px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
  "desktop-xtrawide": 1650px);

$pink: #FF306C;
$blue: #18BFED;
$blueprint: #001E82;

$white: #fff;
$lightblue: #5AD2C8;
$purple: #A541EB;
$orange: #F57D55;
$darkblue: #001E82;
$dark: #191E36;
$salmon: #F57882;

$tournamentWinner: #F47881;
$tournamentTeam: #001E82;


