@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";
.BannerContainer{
  padding-top: 40px;
  max-width: 800px;
  margin: auto;

  a{
    display: flex;
  }
  .BannerDesk{
    display: none;
  }

  @include mq("tablet-wide") {
    .BannerDesk{
      display: block;
    }
    .BannerMob{
      display: none;
    }
  }

}