@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.footer {
    position: relative;
    width: 100%;
    
    font-family: 'PitchSansWeb-Regular', sans-serif;
    color: $dark;
    &:before{
        content: '';
        position: absolute;
        top:0;
        margin: 0 40px;
        width: calc(100% - 100px );
        border-top: 1px solid black;;
    }
    h2{
        font-family: 'RomanaBT-Bold';
    }


  .footerContainer{
    padding: 40px 0;
    @include mq("tablet-wide") {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      h2{
          margin: 0;
          font-size: 32px;
          margin-left:7%;
      }
  }
  }
    
}

.footer h2:after {
    background: #fff;
    @include mq("tablet-wide") {
        content: ':';
        background: none;
        display: inline;
    }
}

.shareLinks {
    display: flex;
    justify-content: center;
    font-size: 13px;
    text-align: center;
    //padding-top:10px;
    cursor: pointer;
    
    
    @include mq("tablet-wide") {
        margin-left:30px;
        margin-right: auto;
    }
    img{
        margin-right:35px;
        // width:55px;
        // border-radius:50%;
        // box-shadow: 5px 5px 10px -3px #000;
        max-width: 28px;
        height: 28px;
        //margin: 7px 14px;
    }
}

.shareText {
    margin-top: -8px;
    padding-right: 4px;
}

.footerLinks {
    
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @include mq("tablet-wide") {
        margin-right: 7%;
        margin-top: 0;
    }
    a, a:visited, a:hover, a:active {
        color: inherit;
        text-decoration: none;
      }
 
}

.footerLinkItem {
    cursor: pointer;
    margin: 4px 15px 0;
}

.footerFeedback{
    text-align: center;
    padding: 30px 30px 0;
    @include mq("tablet-wide") {
      text-align: left;
      padding: 0 15px 0 10px;
    }
    a, a:visited, a:hover, a:active {
      color: inherit;
    }
  }