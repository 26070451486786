@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.header {
    width: 100%;
    height: 300px;
    position: relative;
    font-family: 'PitchWeb-Bold', sans-serif;
    font-size: 23px;
    display: flex;
    overflow: hidden;
    @include mq("tablet-wide") {
        height: 410px;
        font-size: 46px;
    }
}
.feedback{
  background-color: $salmon;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'PitchSansWeb-Regular', sans-serif;
  color: $darkblue;
  font-size: 14px;
  margin-bottom: 5px;
  div{
      text-align: center;
      margin:7px;
  }
  a,:visited{
    color: $darkblue;
      font-weight: bold;
  }
}

.photo {
    position: relative;
    background-color: white;
    width:100%;
    display: flex;
    margin: 0 auto;
    @include mq("tablet-small") {
      width:85%;
    }
    .teamPhotoContainer,.venuePhotoContainer{
      overflow: hidden;
      width:50%;
      display: flex;
      justify-content: center;
      position: relative;
      &:first-of-type{
        margin-right: 2px;
      }
      &:last-of-type{
        margin-left: 2px;
      }
      &.left{
        @include mq("tablet", max) {
          width:100%;
          margin: 0;
        }
        div:last-of-type{
          animation-name: fadeout;
          animation-delay: 3s;
        }
      }
      &.right{
        @include mq("tablet", max) {
          width:0%;
          margin:0;
        }
        div:last-of-type{
          animation-name: fadeout;
          animation-delay: 4s;
        }
      }
      .borderHolder{
        
        height:100%;
        width: 100%;
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        position: absolute;
        &:after{
          content: '';
          position: absolute;
          height:100%;
          width: 100%;
          box-sizing: border-box;
          border: 4px solid var(--teamColor);
        }
        &:last-of-type{
          
          animation-duration: 0.7s;
          animation-fill-mode: forwards;
        }
      }
      img{
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
        opacity: 1;
        position: absolute;
        

      }
    }
    .venuePhotoContainer{
      width:100%;
     }
}

.logoContainer {
    width: 50%;
    margin: auto;
    position: absolute;
    top:50%;
    left: 50%;
    color: #ffffff;
    text-align: center;
    transform: translate(-50%, -50%);
    max-width:345px;   
    @include mq("tablet-wide", max) {
      width: 40%;
    }
    @include mq("tablet", max) {
      width: 50%;
    }
}

.logo {
    position: relative;
    margin: 8px auto 0px;
    @include mq("tablet-wide") {
        margin: 12px auto 0px;
    }
    img{
        width:100%;
    }
    svg {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
}

.welcome, .venue {
    position: relative;
}
.tournamentVenue{
  margin-top: -11%;
}

.logoShadow {
    position: absolute;
    text-align: center;
    width:135%;
    transform: translate(-50%, -50%);
    top:50%;
    left: 50%;
    img{
      width: 100%;
    }
}

@keyframes fadeout {
  0% {opacity: 1;}
  100% {opacity: 0;}
}