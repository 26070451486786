.isCopiedContainer{
  position: fixed;
  top:-85px;
  left:50%;
  transform: translateX(-50%);
  background: #55BEC7;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-family: 'PitchSansWeb-Regular', sans-serif;
  transition: top 1s ease-in-out;
  
  &.active{
    top:20px;
  }
}

