@import-normalize; /* bring in normalize.css styles */
@import "./sharedstyles/variables";
@import "./sharedstyles/mixins";


@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,700');
@font-face {
  font-family: 'PitchSansWeb-Bold';
  src: url('./fonts/PitchSansWeb-Bold.eot');
  src: url('./fonts/PitchSansWeb-Bold.woff2') format('woff2'),
  url('./fonts/PitchSansWeb-Bold.woff') format('woff'),
  url('./fonts/PitchSansWeb-Bold.ttf') format('truetype'),
  url('./fonts/PitchSansWeb-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PitchSansWeb-Light';
  src: url('./fonts/PitchSansWeb-Light.eot');
  src: url('./fonts/PitchSansWeb-Light.woff2') format('woff2'),
  url('./fonts/PitchSansWeb-Light.woff') format('woff'),
  url('./fonts/PitchSansWeb-Light.ttf') format('truetype'),
  url('./fonts/PitchSansWeb-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PitchSansWeb-Medium';
  src: url('./fonts/PitchSansWeb-Medium.eot');
  src: url('./fonts/PitchSansWeb-Medium.woff2') format('woff2'),
  url('./fonts/PitchSansWeb-Medium.woff') format('woff'),
  url('./fonts/PitchSansWeb-Medium.ttf') format('truetype'),
  url('./fonts/PitchSansWeb-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PitchSansWeb-Regular';
  src: url('./fonts/PitchSansWeb-Regular.eot');
  src: url('./fonts/PitchSansWeb-Regular.woff2') format('woff2'),
  url('./fonts/PitchSansWeb-Regular.woff') format('woff'),
  url('./fonts/PitchSansWeb-Regular.ttf') format('truetype'),
  url('./fonts/PitchSansWeb-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PitchWeb-Bold';
  src: url('./fonts/PitchWeb-Bold.eot');
  src: url('./fonts/PitchWeb-Bold.woff2') format('woff2'),
  url('./fonts/PitchWeb-Bold.woff') format('woff'),
  url('./fonts/PitchWeb-Bold.ttf') format('truetype'),
  url('./fonts/PitchWeb-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PitchWeb-Medium';
  src: url('./fonts/PitchWeb-Medium.eot');
  src: url('./fonts/PitchWeb-Medium.woff2') format('woff2'),
  url('./fonts/PitchWeb-Medium.woff') format('woff'),
  url('./fonts/PitchWeb-Medium.ttf') format('truetype'),
  url('./fonts/PitchWeb-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RomanaBT-Bold';
  src: url('./fonts/RomanaBT-Bold.eot');
  src: url('./fonts/RomanaBT-Bold.woff2') format('woff2'),
  url('./fonts/RomanaBT-Bold.woff') format('woff'),
  url('./fonts/RomanaBT-Bold.ttf') format('truetype'),
  url('./fonts/RomanaBT-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


h2 {
  font-family: 'RomanaBT-Bold', sans-serif;
  font-size: 30px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 10px;
  padding: 0 20px;
  color: #1a2a4f; 
  @include mq("tablet-wide") {
    font-size: 40px;
  }

  .teams_section & {
    margin: 0 auto -15px;
    @include mq("tablet-wide") {
      margin: 0 auto -60px;
    }
    
  }
}

// h2:after {
//   background: none repeat scroll 0 0 #000;
//   content: "";
//   display: block;
//   width: 40px;
//   height: 2px;
//   border: 0;
//   margin: 0 auto;
// }

h3 {
  font-family: 'PitchSansWeb-Bold', sans-serif;
  font-size: 22px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 0;
  padding: 0;
  @include mq("tablet-wide") {
    font-size: 45px;
  }
}
img{
  max-width:100%;
}

.section {
  padding: 0px 0 40px 0;
  overflow: hidden;
}
.teams_section{
  margin-top: 20px;
  @include mq("tablet-wide") {
    margin-top: 40px;
  }
  &.player_slider_section{
    overflow: visible;
    margin-top: 0;
  }
}
.no_teams{
  padding: 40px 0 40px 0;
}

.section.grey {
  background-color: #F2EFEA;
}
.section.dark {
  background-color: #0E1915;
}
.section.dark h2 {
  color: #fff;
}
.section.dark h2:after {
  background-color: #fff;

} 
.scrollContainer {
  overflow-x: scroll;
  padding: 0 10px;
}

.team_pink{
  --teamColor: #{$pink};
}
.team_blue{
  --teamColor: #{$blue};
}
.team_purple{
  --teamColor: #{$purple};
}