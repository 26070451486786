@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

:global(.tns-outer){
  width:85%;
  margin:auto;

  @include mq("tablet-small") {
    overflow: hidden;
  }
}
:global(.player_slider_section .tns-outer){
  width:100%;
  margin:auto;
  overflow: hidden;
  :global(.tns-slider){
    margin: auto;
  }
  :global(.tns-item){
   // max-width:250px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
:global(.no_teams .tns-outer){
  width:83%;
}

:global(.tns-ovh) {
  overflow: visible;
}
:global(.tns-item){
  margin: 0 5px;
  @include mq("tablet-small") {
    margin: 0 10px;
  }
}

:global(.tns-slider){
  display: flex;
  justify-content: space-evenly;
  
  &:global(.tns-vertical){
    flex-direction: column;
    align-items: center;
  }
}
:global(.tns-visually-hidden){
  display: none;
}

:global(.games_section .tns-outer){
  :global(.tns-item){
   // max-width: 400px;
  }
  
  @include mq("tablet-small", max) {
    //max-width:340px;
    :global(.tns-item){
      //max-width:340px;
      margin: 0 10px;
    }
  }
}
// :global(.scale-slider .tns-outer){
//   width:300px;

//   @include mq("phone", max) {
//     width: 273px;
//   }
//   @include mq("tablet-small") {
//     width: 85%;
//   }
//   @include mq("tablet-wide") {
//     width: 85%;
//     overflow: hidden;
//   }

// }
:global(.iframeContainer){
  width:100%;
  padding-bottom:56.25%;
  position: relative;
}
:global(.sliderVideoPlayer){
 pointer-events: none;
}

/* :global(.scale-slider .tns-slide-active img){
  margin:auto;
}
:global(.scale-slider .tns-slide-active~.tns-item img){
  margin-left:-32px;
} */

