@import "../sharedstyles/variables";
@import "../sharedstyles/mixins";

.sliderContainer {
    display: flex;
    position: relative;
}
:global(.fslightbox-source-inner){
    .gamesItem{
        width:100%!important;
        height:auto!important;
        // @include mq("tablet") {
        //     .largeText{
        //         font-size: 100px;
        //         line-height: 100px;
        //     }
        //     .smallText{
        //         font-size: 80px;
        //         line-height: 80px;
        //     }
        // }
    }
}

.gamesItem {
    position: relative;

}

:global(.tns-item) .gamesTile{
    margin: auto;
    height:0;
    padding-bottom:100%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
   
    &:before{
      content:'';
      background-image: url("../assets/img/hex-tile.png");
      background-repeat: repeat ;
      position: absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      opacity:0.06;
      background-size: 40px;
      z-index: 0;
    }
    &.darkblue{
      &:before{
        background-image: url("../assets/img/hex-tile-mint.png");
      }
    }
    // @include mq("phone", max) {
    //     width: 273px;
    //     height: 273px;
    // }
    // @include mq("tablet-small", max) {
    //     width:300px;
    //     height: 300px;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    // }
    &.game_no-1{
      @include mq("tablet-small"){
      display: flex;
      height:17vw;
      padding-bottom: 0;
      .textContainer{
        width: 80%;
        max-width: 80%;
        margin-top: 2%;
      }
      .gameLogoContainer{
        height: 100%;
        width: 25%;
        right: 5%;
        bottom: auto;
      }
      .playerBorder{
        width: 14%;
        padding-bottom: 14%;
      }
      .playerName{
        margin-left:-12%;
        padding-left:12%;
        font-size: 24px;
        width:33%;
        line-height: 45px;
        height:45px;
        min-width: 33%;
        &:before{
          border-width: 45px 24px 0 24px;
        }
      }
      .gameMessage{
        width:100%;
        font-size: 2vw;
        padding-top: 2%;
      }
      .playerContainer{
        padding-top: 0;
      }
    }
    }
    &.game_no-2{
      @include mq("tablet-wide"){
        display: flex;
        height:17vw;
        padding-bottom: 0;
        .textContainer{
          width: 40%;
        }
        .gameLogoContainer{
          height: 100%;
          width: 30%;
          right: 5%;
          bottom: auto;
        }
        .playerBorder{
          width: 20%;
          padding-bottom: 20%;
        }
        .playerName{
          margin-left:-24%;
          padding-left:24%;
          font-size: 30px;
        }
        .playerContainer{
          padding-top: 0;
        }
        .gameMessage{
          width:75%;
        }
      }
    }
    &.game_no-3{
      @include mq("tablet-wide", max){
        padding-bottom:69%;
      }
      padding-bottom:65%;
      .playerBorder{
        width: 25%;
        padding-bottom: 25%;
      }
      .playerContainer{
        padding-top: 5%;
        left: 10%;
      }
    }
    &.game_no-4, &.game_no-2{
      @include mq("tablet-wide", max){
        padding-bottom:69%;
        .playerBorder{
          width: 25%;
          padding-bottom: 25%;
        }
        .playerContainer{
          padding-top: 5%;
          left:10%;
        }
      }
    }
    &.game_no-1{
      @include mq("tablet-small", max){
        padding-bottom:69%;
        .playerBorder{
          width: 25%;
          padding-bottom: 25%;
        }
        .playerContainer{
          padding-top: 5%;
          left:10%;
        }
      }
    }
}

.lightblue{
  background-color: $lightblue;
}
.purple{
  background-color: $purple;
}
.darkblue{
  background-color: $darkblue;
}
.orange{
  background-color: $orange;
}

.gamesTitleContainer{
  height:0;
  display:flex;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 35%;
  position: relative;
}

.playerContainer{
  width:100%;
  margin:auto;
  display: flex;
  margin-left: 8%;
   padding-top: 10%;
   flex:2;
   position: relative;
   z-index: 10;
}
.textContainer{
  //display:flex;
  flex-direction: column;
  max-width: 70%;
  margin-top: 3%;
}
.playerName{
  margin-left:-22%;
  padding-left:22%;
  position: relative;
  font-family: 'PitchWeb-Bold', serif;
  letter-spacing: 2px;
  font-size: 4vw;
  min-width:44%;
  //width: 70%;
  line-height: 34px;
  height:34px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  
  .lightblue &, .orange &, .purple & {
    background: $darkblue;
    color: $white;
    fill: $white;
  }
  .darkblue & {
    background: $lightblue;
    color: $darkblue;
    fill: $darkblue;
    &:before{
      border-color: $lightblue transparent transparent transparent;
    }
  }

  &:before{
    content: '';
    position: absolute;
    top:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 34px 24px 0 24px;
    border-color: $darkblue transparent transparent transparent;
    right:-24px;
    z-index: -1;
  }
  @include mq("phone-wide") {
    font-size: 3.5vw;
  }
  @include mq("tablet-small") {
    font-size: 2.3vw;
  }
  @include mq("tablet-wide") {
    font-size: 1.2vw;
  }

  @include mq("desktop") {
    line-height: 40px;
    height:40px;
    font-size: 1.5vw;
    &:before{
      border-width: 40px 24px 0 24px;
    }
  }
  svg{
    z-index: 10;
  }
  
} 
.playerBorder{
  @include rounded-hex($main-radius: 40%,
  $rounding-radius: 5%,
  $rotated: true);
  padding: 3px;
  height: 0;
  width:27%;
  padding-bottom: 27%;
  position: relative;
  z-index: 10;
  .lightblue &, .orange &, .purple & {
    background: $darkblue;
  }
  .darkblue & {
    background: $lightblue;
  }
}
.gamePlayer{
  @include rounded-hex($main-radius: 40%,
              $rounding-radius: 5%,
              $rotated: true);
  position: absolute;
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  object-fit: cover;

}
.gameLogoContainer{
  width: 100%;
  height: 37%;
  bottom: 7%;
  position: absolute;
  z-index: 10;
  @include mq("phone-wide") {
    bottom: 10%;
  }

  .gameLogo{
    max-height:100%;
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}


.gameMessage{
  width:90%;
  font-family: 'RomanaBT-Bold', sans-serif;
  text-transform: uppercase;
  //margin-top: 20px;
  color: white;
  font-size: 3.8vw;
  fill: #fff;
  padding-top: 3%;
  padding-left:2%;
  
  @include mq("tablet-small") {
    font-size: 2vw;
    
  }
  @include mq("tablet-wide") {
    font-size: 1.4vw;
    width:86%;
  }

  &.lightblue{
    background-color: transparent;
    color: $lightblue;
    fill: $lightblue;
  }
  &.darkblue{
    background-color: transparent;
    color: $darkblue;
    fill: $darkblue;
  }

}

