@use 'sass:math';

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}


@mixin rounded-hex($main-radius: 30%, $rounding-radius: 10%, $rotated: false, $precision: 20) {
	$n: 6;
	$central-angle: 360deg/$n;
	$angle: ($n - 2)*180deg/$n;
	$max-var-angle: 2*(90deg - $angle/2);
	$precision: 6;
	$unit-var-angle: $max-var-angle/$precision;
	
	$r-diff: $main-radius + $rounding-radius;

	$points: ();

	@for $i from 0 to $n {
		$vertex-angle: $i*$central-angle + if($rotated, -90deg, 0deg);
		$vertex-x: calc(50% + $r-diff * math.cos($vertex-angle));
		$vertex-y: calc(50% + $r-diff * math.sin($vertex-angle));

		@for $j from 0 through $precision {
			$curr-angle: $vertex-angle + 
				($j - .5*$precision)*$unit-var-angle;
			$x:  calc($vertex-x + $rounding-radius * math.cos($curr-angle));
			$y:  calc($vertex-y + $rounding-radius * math.sin($curr-angle));

			$points: $points, $x $y;
		}
	}
	
	clip-path: polygon($points);
}